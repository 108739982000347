import React from "react";
import { Link } from "gatsby";

import navbarStyles from "./navbar.module.css";

export default function Navbar() {
  return (
    <nav className={navbarStyles.navbar}>
      <ul>
        <li>
          <Link to="/contact/" activeClassName="{navbarStyles.active}">
            Contact
          </Link>
        </li>
        <li>
          <Link to="/prestations/" activeClassName="active">
            Prestations
          </Link>
        </li>
      </ul>
    </nav>
  );
}
