import React from "react";
import { Link } from "gatsby";

import SEO from "../components/seo";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

import logo from "../assets/img/logo-big.png";

export default function Layout({ children, title, seoTitle }) {
  return (
    <div>
      <SEO title={seoTitle ? seoTitle : title} />
      <header>
        <div className="header__border-top"></div>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <div className="col-6">
              <Navbar />
            </div>
          </div>
          <h1>{title}</h1>
          {title === "JLTWeb.info" && (
            <p>Site professionnel de Julien LE THUAUT</p>
          )}
        </div>
      </header>
      <div className="content">{children}</div>
      <Footer />
    </div>
  );
}
