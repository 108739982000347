import React from "react";
import footerStyles from "./footer.module.css";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { FaTwitter, FaLinkedin } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className={footerStyles.footer}>
      <div className="container">
        <div className="footer__social row">
          <div className="col-6 center">
            <OutboundLink
              href="https://www.linkedin.com/in/jlethuaut/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin size={20} style={{ fill: '#ffffff', verticalAlign: 'text-bottom' }} /> Voir mon profil LinkedIn
            </OutboundLink>
          </div>
          <div className="col-6 center">
            <OutboundLink
              href="https://twitter.com/JulienLeThuaut"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter size={20} style={{ fill: '#ffffff', verticalAlign: 'text-bottom' }} /> Me suivre sur Twitter
            </OutboundLink>
          </div>
        </div>
        <p className="footer__copyright">
          Julien Le Thuaut © 2020 - JLTWeb, tous droits réservés
        </p>
      </div>
    </footer>
  );
}
